// File: src\components\TokenBuilder\index.tsx

import React from 'react';
import styled from 'styled-components';
import { useDarkModeManager } from '../../state/user/hooks';
import Logo from '../../assets/svg/toolslight.png';
import LogoDark from '../../assets/svg/toolsdark.png';

const StyledMenuButton = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;

  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }

  img {
    margin-top: 2px;
  }
`;

const StyledMenu = styled.div`
  margin-left: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`;

const NotificationDot = styled.div`
  position: absolute;
  top: 0;
  left: 5px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
`;

export default function TokenBuilder() {
  const [darkMode] = useDarkModeManager();
  return (
    <StyledMenu>
      <StyledMenuButton as="a" href="https://lab.asteroneo.com" target="_blank" className="router_btn">
        <img width={'20px'} src={darkMode ? Logo : LogoDark} alt="logo" />
        <NotificationDot />
      </StyledMenuButton>
    </StyledMenu>
  );
}