// File: src\components\banner\index.tsx

import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export default function TopSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="top_sider_main">
        <Slider {...settings}>
            <a href="https://lab.asteroneo.com" target="_blank">
              <div>
                  <img src="/images/banner4.jpg" alt="" className="" />
              </div>
            </a>
            <div>
                <img src="/images/banner3.jpg" alt="" className="" />
            </div>
            <a href="https://www.neonauts.club" target="_blank">
              <div>
                  <img src="/images/banner2.jpg" alt="" className="" />
              </div>
            </a>
            <div>
              <img src="/images/banner.jpg" alt="" className="" />
            </div>
        </Slider>
      </div>
    </>
  );
}
